// assets/js/monjs.js
//var $ = require('jquery');
const $ = require('jquery');

// ceci pour mettre le nom du fichier chargé dans le champ de l'input (pour bootstrap4)
// ici solution trouvée à https://github.com/twbs/bootstrap/issues/20813#issuecomment-400565761
$(document).on('change', '.custom-file-input', function () {
    let fileName = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
    $(this).parent('.custom-file').find('.custom-file-label').text(fileName);
    console.log("Je suis passé par le script du git qui donne la solution pour mettre le nom du fichier dans l'input du formulaire => "  + fileName);
});

/*ceci est ma version remplacée par la version ci-dessus trouvée sur internet à https://github.com/twbs/bootstrap/issues/20813#issuecomment-400565761
inputfile = $(':file');
inputfile.change(function () {
    var i = 0;
    var nomfichier ="";
    $.each(inputfile, function (index, value) {
        //alert(value.prop('files')[0].name);
        if (typeof inputfile[i].files[0] !== "undefined") {
             nomfichier = inputfile[i].files[0].name;
            $('.custom-file-label:eq('+i+')').text(nomfichier);
        }
        i++;
        //$('.custom-file-label').text(value.files[0].name);
    });

    console.log('Je suis passé par le script qui a mis le nom du fichier => ' + nomfichier);
});*/
console.log('Mets tes codes personnels dans assets/js/monjs.js!!!');
// ************** fin fonction inputfile.....